<template>
    <div>
        <CRow>
            <CCol col="12">
                <CCard>
                    <CCardHeader>Ürün Oluştur</CCardHeader>
                    <CCardBody>
                        <CAlert v-on:click="closeAlert()" v-if="alert.isOpen" show :color="alert.color">
                            {{ alert.message }}
                        </CAlert>
                        <CForm>
                            <CInput
                                label="Ürün ismi"
                                horizontal
                                autocomplete="productName"
                                :value.sync="product.name"
                            />
                            <CInput
                                label="Alt başlık"
                                description="Ürün isminin yanındaki yazı. UNISEX gibi"
                                horizontal
                                :value.sync="product.subtitle"
                            />
                            <label>Ürün Açıklaması</label>
                            <VueEditor v-model="product.description" />
                            <CRow class="form-group" form>
                                <CCol col="6">
                                    <CInput
                                        label="Yurtiçi fiyatı"
                                        horizontal
                                        :value.sync="product.price"
                                    />
                                    <CInput
                                        label="Sepette indirim"
                                        horizontal
                                        :value.sync="product.campaign.discount_percent"
                                        description="Yüzde olarak girilmelidir"
                                    />
                                </CCol>
                                <CCol col="6">
                                    <CInput
                                        label="Yurtdışı fiyatı"
                                        horizontal
                                        :value.sync="product.usd_price"
                                    />
                                    <CInput
                                        label="Sıra"
                                        horizontal
                                        :value.sync="product.order"
                                    />
                                </CCol>
                            </CRow>

                            <CInput
                                label="Kampanya Metni"
                                horizontal
                                :value.sync="product.campaign.text"
                            />
                            <CSelect
                                label="Kategori"
                                horizontal
                                :value.sync="product.category"
                                :options="productCategories"
                                placeholder="Kategori seçiniz"
                            />

                            <CRow form class="form-group">
                                <CCol tag="label" sm="3" class="col-form-label">
                                    Ücretsiz Kargo
                                </CCol>
                                <CCol sm="9">
                                    <CSwitch
                                        class="mr-1"
                                        color="primary"
                                        :checked.sync="product.campaign.free_shipment"
                                    />
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        <CButton
                            type="button"
                            size="sm"
                            color="primary"
                            v-on:click="save()"
                        >
                            <CIcon name="cil-check-circle"/> Kaydet
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';

import { getProductCategories } from '../../utils';


export default {
    name: 'ProductCreate',

    components: {
        VueEditor,
    },

    data () {
        return {
            product: {
                name: '',
                subtitle: '',
                description: '',
                price: 0,
                usd_price: 0,
                campaign: {
                    discount_percent: 0,
                    free_shipment: false,
                    text: ''
                },
                category: '',
                sizes: []
            },
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
            productCategories: getProductCategories()
        }
    },

    methods: {
        closeAlert() { this.alert.isOpen = false; },

        async save() {
            try {
                const response = await axios({
                    method: 'post',
                    url: '/products',
                    data: {
                        ...this.product,
                        campaign: this.product.campaign.text && this.product.campaign.text.length > 0 ? ({
                            ...this.product.campaign,
                            discount_percent: Number(this.product.campaign.discount_percent)
                        }): null,
                        order: Number(this.product.order),
                        price: Number(this.product.price.toString().replace(',', '.')),
                        usd_price: Number(this.product.usd_price.toString().replace(',', '.'))
                    }
                });

                if (response.status === 201) {
                    this.$router.push({ path: `${response.data._id}` });
                }
            } catch (error) {
                console.log(error);
                let message = 'İşlem gerçekleştirilirken bir hata oluştu';
                if (error.isAxiosError) {
                    message = error.response.data.message;

                    if (typeof message !== 'string') {
                        message = message[0];
                    }
                }
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message
                };
            }
        },
    },

    async mounted() {
        const response = await axios({
            method: 'get',
            url: '/products/first'
        });

        this.product.order = Number(response.data.order) + 100;
    }
}
</script>

<style lang="scss" scoped>
    label {
        margin-bottom: 15px;
    }
</style>
